<template>
  <div>
    <welcome-conference-banner class='py-8' background-color='#FAFAFA'/>
    <div class='pt-8 px-2'>
      <button
        class='lg:hidden w-full py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
        :style="`background-color: ${eventMainThemeColor};`"
        @click='goToCurrentSession'>
        입장하기
      </button >
    </div>
    <div class='relative pt-8 overflow-x-auto' :style='setupVariableColors'>
      <table class='w-full text-gray-900 text-xs lg:text-sm'>
        <thead>
          <tr>
            <th class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>시간</th>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
            <th class='border-b px-2 py-2 text-left lg:w-1/3'>프로그램</th>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
            <th class='border-b px-2 py-2 text-left w-1/3 lg:w-auto'>좌장 및 연자</th>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto' rowspan='3'>13:00 - 13:10</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>개회사</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>
              <div>
                김명구 
                <span class='block lg:inline-block lg:ml-1 opacity-70'>(인하의대, 대한정형외과학회 회장)</span>
              </div>
              <div class='mt-2'>
                황일웅 
                <span class='block lg:inline-block lg:ml-1 opacity-70'>(서울의대, 군진의학위원회 위원장)</span>
              </div>
            </td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell' rowspan='3'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>축사</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>
              <div>
                최병섭 
                <span class='block lg:inline-block lg:ml-1 opacity-70'>(국군의무사령부, 의무사령관)</span>
              </div>
            </td>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>진행</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>
              <div>
                이봉근 
                <span class='block lg:inline-block lg:ml-1 opacity-70'>(한양의대, 군진의학위원회 간사)</span>
              </div>
              <div class='mt-2'>
                문기호 
                <span class='block lg:inline-block lg:ml-1 opacity-70'>(국군수도병원, 군진의학위원회 간사)</span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr class='border-t border-gray-600 font-semibold' style='background-color:var(--lightColor);'>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>13:10 - 14:40</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>Session I</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>
              <div>
                양규현 
                <span class='block lg:inline-block lg:ml-1 opacity-70'>(국군수도병원)</span>
              </div>
              <div class='mt-2'>
                황일웅 
                <span class='block lg:inline-block lg:ml-1 opacity-70'>(서울의대, 군진의학위원회 위원장)</span>
              </div>
            </td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>13:10 - 13:30</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell w-24'></td>
            <td class='border-b px-2 py-2'><span class='font-medium'>Trauma</span> : Syndesmosis injury; treat or not</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>손훈상 <span class='block lg:inline-block lg:ml-1 opacity-70'>(연세원주의대)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>13:30 - 13:40</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>질의 및 응답</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>10분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>13:40 - 14:00</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'><span class='font-medium'>Spine</span> : Young patients 에서의 disc degeneration disease;진단 및 대증적 운동치료</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>손희중 <span class='block lg:inline-block lg:ml-1 opacity-70'>(을지의대)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>14:00 - 14:10</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>질의 및 응답</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>10분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>14:10 - 14:30</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'><span class='font-medium'>Hand</span> : TFCC injury;when to repair</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>최윤락 <span class='block lg:inline-block lg:ml-1 opacity-70'>(연세의대)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>14:30 - 14:40</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>질의 및 응답</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>10분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='font-semibold bg-gray-200'>
            <td class='border-b border-gray-600 px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>14:40 - 15:00</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b border-gray-600 px-2 py-2'>휴식</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b border-gray-600 px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b border-gray-600 px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
        </tbody>
        <tbody>
          <tr class='border-t border-gray-600 font-semibold' style='background-color:var(--lightColor);'>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>15:00 - 16:00</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>Session II</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>
              <div>
                전재명 
                <span class='block lg:inline-block lg:ml-1 opacity-70'>(국군수도병원)</span>
              </div>
              <div class='mt-2'>
                이진우 
                <span class='block lg:inline-block lg:ml-1 opacity-70'>(연세의대, 대한정형외과학회 이사장)</span>
              </div>
            </td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>15:00 - 15:20</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>
              <span class='font-medium'>Knee</span> : Around knee osteotomy: complication related technical tips & treatment strategy
            </td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>배지훈 <span class='block lg:inline-block lg:ml-1 opacity-70'>(고려의대)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>15:20 - 15:30</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>질의 및 응답</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>10분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>15:30 - 15:50</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>
              <span class='font-medium'>Shoulder</span> : Periscapular pain;cause and treatment
            </td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>천용민 <span class='block lg:inline-block lg:ml-1 opacity-70'>(연세의대)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>15:50 - 16:00</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>질의 및 응답</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>10분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
        </tbody>
        <tbody>
          <tr class='border-t border-gray-600 font-semibold' style='background-color:var(--lightColor);'>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>16:00 - 17:00</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>Session Ⅲ</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>
              <div>
                윤형구 
                <span class='block lg:inline-block lg:ml-1 opacity-70'>(국군수도병원)</span>
              </div>
              <div class='mt-2'>
                정홍근
                <span class='block lg:inline-block lg:ml-1 opacity-70'>(건국의대, 대한정형외과학회 차기이사장)</span>
              </div>
            </td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>16:00 - 16:20</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>
              <span class='font-medium'>Hip</span> : hip의 stress 골절과 무혈성 괴사와의 감별
            </td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>정회정 <span class='block lg:inline-block lg:ml-1 opacity-70'>(연세원주의대)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>16:20 - 16:30</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>질의 및 응답</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>10분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>16:30 - 16:45</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>
              <span class='font-medium'>Foot and Ankle</span> : 반복되는 microtrauma에 노출되어있는 외측인대 불안정증 환자 치료 guideline: 보존적 치료 지속 or 수술, 구체적 방법
            </td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>15분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>김성재 <span class='block lg:inline-block lg:ml-1 opacity-70'>(한림의대)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>16:45 - 17:00</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>
              <span class='font-medium'>Foot and Ankle</span> : OCD의 수술적 치료 원칙 및 적응증
            </td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>15분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>한승환 <span class='block lg:inline-block lg:ml-1 opacity-70'>(연세의대)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>17:00 - 17:15</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>질의 및 응답</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>15분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td class='border-b border-gray-600 px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>17:15 - 17:30</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b border-gray-600 px-2 py-2'>총론 및 폐회사</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b border-gray-600 px-2 py-2 w-1/3 lg:w-auto'>최윤락 <span class='block lg:inline-block lg:ml-1 opacity-70'>(연세의대, 대한정형외과학회 총무이사)</span></td>
            <th class='border-b border-gray-600 px-2 py-2 text-left hidden lg:table-cell'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WelcomeConferenceBanner from '@/components/welcome/WelcomeConferenceBanner.vue'

export default {
  name: 'KoaMmCustomProgram',
  components: {
    WelcomeConferenceBanner,
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
      'colorsInRange',
    ]),
    setupVariableColors () {
      return {
       '--lightestColor': this.colorsInRange[0],
       '--lightColor': this.colorsInRange[1],
       '--inputColor': this.colorsInRange[2],
       '--darkColor': this.colorsInRange[3], 
      }
    },
  },
  methods: {
    goToCurrentSession () {
      this.$router.push({name: 'CurrentSession'}).catch(() => {})
    }
  }
}
</script>
